import landingImage from "../../img/air-pollution.gif";

export default function LandingPage() {
	return (
		<>
			<h1 className="landing-header">
				Bun venit pe site-ul nostru dedicat monitorizării calității aerului!
			</h1>
			<div className="landing-page c_style">
				<div className="landing-image">
					<img src={landingImage} alt="factory-img" className="l-im" />
				</div>
				<div className="landing-info">
					<p>
						Utilizăm o varietate de senzori specializați pentru a măsura cu
						precizie parametrii cheie ai calității aerului, furnizând date brute
						și actualizate în timp real.
					</p>
					<p>
						Aceste date sunt prezentate în grafice informative și ușor de
						înțeles, astfel încât să puteți vedea cum se schimbă calitatea
						aerului în timpul zilei.
					</p>
					<p>
						Accesând aceste informații, puteți lua decizii informate în ceea ce
						privește activitățile pe care le desfășurați în aer liber și puteți
						lua măsuri de precauție pentru a vă proteja sănătatea și a vă
						menține în siguranță.
					</p>
				</div>
			</div>
		</>
	);
}
