const channel = {
	518: "PM 10",
	501: "Temperature",
	516: "PM 1.0",
	504: "iZiAiR Air Quality Level",
	505: "iZiAiR Identified Risk",
	503: "CO2",
	512: "Average Noise",
	510: "Luminosity",
	508: "VOC",
	515: "Pressure",
	502: "Humidity",
	100: "Signal",
	513: "Maximum Noise",
	514: "iZiAiR Recommendation",
	517: "PM 2.5",
	511: "Occupancy Rate",
	520: "Power Consumption",
	521: "Energy Stored",
	104: "Location",
	102: "Location",
	5: "Baterry",
};

export default channel;
